import React from "react"

const html = `<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>SoCash Store Locator</title>
  </head>
  <body>
    <div id="storepoint-container" data-map-id="1629db8d9ad90f"></div>
    <script>
      (function () {
        var a = document.createElement("script");
        a.type = "text/javascript";
        a.async = !0;
        a.src = "https://cdn.storepoint.co/api/v1/js/1629db8d9ad90f.js";
        var b = document.getElementsByTagName("script")[0];
        b.parentNode.insertBefore(a, b);
      })();
    </script>
  </body>
</html>`

function cashinstore() {
  return (
    <>
      <div style={{ width: "100%", height: "100vh", overflowY: "hidden" }}>
        <iframe
          srcDoc={html}
          width="100%"
          height="100%"
          style={{ border: "none" }}
        />
      </div>
    </>
  )
}

export default cashinstore
